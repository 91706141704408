<template>
  <section
    class="section shipping-sec  test"
  >
    <!-- 普通免邮、平台免邮、首单免邮、平台首单免邮 -->
    <!-- PS:根节点层级的注释会影响测试环境打包时, 父组件透传属性如class之类 -->
    <div class="section-l">
      <sui_icon_shipping_18px
        class="icon_shipping"
        size="18px"
      />
    </div>
    <div class="section-r">
      <p
        class="title"
        da-expose-code="cart-platform-shipping-block"
      >
        <span>{{ language.SHEIN_KEY_PC_15066.replace(':','') }}</span>
        <a
          v-if="showShipSupplement"
          class="operation"
          role="button"
          tabindex="0"
          @keydown.stop.enter="clickCartAdd"
          @click="clickCartAdd"
        >
          {{ language.SHEIN_KEY_PC_15215 }}
          <sui_icon_more_right_12px
            :is-rotate="locals.GB_cssRight"
            size="12px"
          />
        </a>
      </p>
      <ul class="contain not-fsp-element">
        <li v-if="shippingInfo">
          <p class="contain-tips">
            <span v-html="shippingInfo.freeShippinpTips"></span>
          </p>
          <ClientOnly>
            <count-down-time
              v-if="shippingInfo.showCountDown && shippingInfo.countDownTime > 0"
              :time-stamp="shippingInfo.countDownTime"
              :is-left-time="true"
              show-type="inline-promotion"
              :show-end-tips="true"
            />
          </ClientOnly>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapState, mapMutations } from 'vuex'
import { daEventCenter } from 'public/src/services/eventCenter'
import { sui_icon_shipping_18px, sui_icon_more_right_12px } from '@shein-aidc/icon-vue3'
daEventCenter.addSubscriber({ modulecode: '1-7-1' })
const daEventExpose = daEventCenter.getExposeInstance()

export default {
  name: 'NewShippingInfo',
  components: {
    sui_icon_shipping_18px,
    sui_icon_more_right_12px
  },
  data() {
    return {
      shippingInfo: null,
    }
  },
  computed: {
    ...mapState(['language', 'isPlatform', 'ABTParams', 'bottomActivityConfig', 'locals']),
    ...mapGetters(['topShippingTips', 'singleMallCode', 'newFreeShippingInfo', 'cartAddTradeGoodsDetailSwitch']),
    showShipSupplement () {
      return this.shippingInfo?.showShipSupplement
    },
  },
  watch: {
    'shippingInfo.freeShippinpTips': {
      deep: true,
      immediate: true,
      async handler (n, o) {
        if(typeof window === 'undefined') return
        if (n && n != o) {
          daEventCenter.triggerNotice({
            daId: '1-7-5-3',
            extraData: this.getExtraData()
          })
        }
      }
    },
    bottomActivityConfig: {
      handler(val) {
        const {
          couponIsReady,
          freeshipIsReady,
        } = val

        if (couponIsReady && freeshipIsReady) {
          this.shippingInfo = this.newFreeShippingInfo
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.initExpose()
  },
  methods: {
    ...mapMutations(['updateAddForCoupon', 'updateField']),
    clickCartAdd: function (){
      if (!this.shippingInfo?.showShipSupplement) return
      
      daEventCenter.triggerNotice({
        daId: '1-7-5-4',
        extraData: this.getExtraData()
      })

      if (this.shippingInfo.type === 'coupon') {
        this.updateAddForCoupon({
          show: true,
          type: 'coupon',
          coupon: this.shippingInfo?._originInfo?.coupon,
          config: {
            isClickToDetail: this.cartAddTradeGoodsDetailSwitch,
          }
        })
        return
      }
      const addType = this.shippingInfo?._originInfo?.firstFreeShipping?.firstFreeShippingTip ? 998 : 999
      this.updateField({ key: 'freeShippingAddOnType', value: this.isPlatform ? 'platform' : this.singleMallCode })
      this.updateField({ key: 'isMoreMallFreeShippingAddOn', value: false })
      this.updateAddForCoupon({ 
        show: true, 
        type: 'AddOnV2', // V2版本走重构后的弹窗
        promotion: {
          promotion_id: addType,
        },
      })
    },
    initExpose() {
      daEventExpose.subscribe && daEventExpose.subscribe({
        keycode: 'cart-platform-shipping-block`1-7-1-21',
      })
    },
    getExtraData() {
      let is_fullshippingactivity = 0
      let origin_price = ''
      let shipping_method = ''
      let mall_code = ''
      let shipping_free_type = ''

      if (this.shippingInfo?.type === 'coupon') {
        is_fullshippingactivity = this.showShipSupplement ? 0 : 1
        origin_price = this.shippingInfo?._originInfo?.other_coupon_rule?.[0]?.minOrderPrice?.amountWithSymbol || ''
        shipping_method = this.shippingInfo?._originInfo?.shipping_list?.map(item => item.shipping_method_name)?.join(',') || ''
        mall_code = this.shippingInfo?._originInfo?.mall?.mall_code || ''
        shipping_free_type = this.shippingInfo?._originInfo?.coupon_dimension == 1 ? 0 : 3
      } else {
        is_fullshippingactivity = this.showShipSupplement ? 0 : 1
        origin_price = this.shippingInfo?._originInfo?.shippingInfoAvailable?.origin_price?.amountWithSymbol || '-'
        shipping_method = this.shippingInfo?.shippingMethod || this.shippingInfo?._originInfo?.shippingInfoAvailable?.shipping_method_name || ''
        mall_code = this.isPlatForm ? '' : this.singleMallCode
        shipping_free_type = this.isPlatForm ? 0 : 3
      }

      return {
        type: this.shippingInfo?.type || '',
        is_fullshippingactivity,
        mall_code,
        origin_price,
        shipping_method,
        shipping_free_type,
      }
    }
  }
}
</script>

<style lang='less' scoped>
.shipping-sec {
  border: 0.5px solid rgba(25, 128, 85, 0.3);
}
.icon_shipping{
  color: #222222;
}
.contain-tips {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
</style>
