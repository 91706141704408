<template>
  <div>
    <s-alert
      v-if="showNotice"
      type="info"
      :closeable="true"
      :max-rows="3"
      :load-more-txt="language['SHEIN_KEY_PC_15600']"
      class="alert cart-top-notice"
      :description="noticeMessage"
      @close="close"
      @on-load-more-click="onLoadMoreClick"
    >
      <template #suffixIcon>
        <icons-alert-notice />
      </template>
    </s-alert>

    <s-dialog
      v-model:visible="isShowNotic"
      type="W720"
      :show-close="false"
      :close-on-click-modal="false"
    >
      <div class="notice-tips-wrapper">
        {{ htmlDecode(noticeMessage) }}
      </div>
      <template #footer>
        <s-button
          :type="['primary', 'H36PX']"
          @click="noticClosehandle"
        >
          {{ language.SHEIN_KEY_PC_15216 }}
        </s-button>
      </template>
    </s-dialog>
  </div>
</template>

<script>
import { daEventCenter } from 'public/src/services/eventCenter'
import { htmlDecode as _htmlDecode } from '@shein/common-function'
// import {
//   IconsAlertNotice,
// } from '@shein-aidc/sui-icons';
import { IconsAlertNotice } from '@shein-aidc/icons-laptop'
import schttp from 'public/src/services/schttp'
import { mapState, mapMutations } from 'vuex'
import { isLogin } from 'public/src/pages/common/utils/index.js'

export default {
  components: {
    IconsAlertNotice
  },
  props: {
    cartNum: {
      type: Number,
      default: 0
    },
  },
  data () {
    return {
      isShowNotic: false
    }
  },
  computed: {
    ...mapState(['noticeMessage', 'language', 'loginStatus']),
    showNotice(){
      return !(!this.loginStatus && this.cartNum) && this.noticeMessage // 未登录非空车不展示
    },
  },
  watch: {
    showNotice(n){
      if(n){
        daEventCenter.triggerNotice({ daId: '1-7-1-8' })
      }
    }
  },
  updated() {
    this.updateField({ key: 'loginStatus', value: isLogin() })
  },
  mounted () {
    if(!this.noticeMessage){
      this.getCartNotice()
    }
  },
  methods: {
    ...mapMutations(['updateField']),
    htmlDecode: function (text){
      return _htmlDecode({ text: text })
    },
    noticClosehandle () {
      daEventCenter.triggerNotice({ daId: '1-7-1-11' })
      this.isShowNotic = false
    },
    close () {
      daEventCenter.triggerNotice({ daId: '1-7-1-9' })
    },
    onLoadMoreClick () {
      daEventCenter.triggerNotice({ daId: '1-7-1-10' })
      this.isShowNotic = true
    },
    getCartNotice () {
      schttp({
        url: '/api/cart/communalNoticeCart/get'
      }).then(res => {
        if (res.code == 1000) {
          this.updateField({ key: 'noticeMessage', value: res?.data?.content || '' })
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.alert {
  margin-bottom: 20px;
  padding: 12px 16px 12px 24px;
  min-height: 44px;
}
.cart-top-notice{
  margin-bottom: 20px;
  max-height: 72px;
  padding: 12px 16px 12px 24px;
  overflow: hidden;
  /deep/ .sui-alert__description {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }
}
.notice-tips-wrapper {
  width: 624px;
  max-height: 430px;
  overflow: auto;
  background-color: #FAFAFA;
  padding: 24px 5px 24px 5px;
  line-height: 24px;
  font-size: 14px;
}
</style>
